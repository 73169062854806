import React, {SVGProps} from 'react';

const Waves = (props: SVGProps<SVGSVGElement> & {
	fillColor: string;
}) => (
	<svg fill="none" viewBox="0 0 1440 129.9">
		<path fill={props.fillColor} d="M1033,91.8c-141.8,22.4-242.3-55.1-406.5-4.7c-150.4,46.2-221.7,53.5-329.5,9.2S160-25,1,7.6
		C0.7,7.7,0.3,7.8,0,7.8v124.7h1440V22.4c-18.8-9.1-44.5-14.8-82.2-14.8C1225.5,7.6,1156,74.1,1033,91.8z"/>
	</svg>

);

export default Waves;
