import React from 'react';

import tw, {styled} from 'twin.macro';

export const Title = styled.h1(
	() => [
		tw`text-4xl
        leading-none
        font-extrabold
        text-white
        sm:text-5xl
        sm:leading-none
        sm:tracking-tight
        lg:text-6xl`
	]
);

export const Subtitle = styled.h2(
	() => [
		tw`mt-5
        text-xl
        leading-7
        text-indigo-300`
	]
);

export const Indicator = styled.span(
	() => [
		tw`text-3xl
        leading-10
        font-light
        font-mono
        text-secondary-background
        sm:text-4xl
        sm:leading-none
        sm:tracking-tight
        lg:text-4xl`
	]
);
