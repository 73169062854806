import React from 'react';
import {Link} from 'gatsby';

import tw, {styled, theme} from 'twin.macro';

import World from '@/components/illustrations/world';
import Wave from '@/components/illustrations/wave';
import {Title, Subtitle} from '@/components/hero';
import {PRICE} from "@/constants";

const Wrapper = styled.div`
	
`;
const Asterisk = styled.em`
	${tw`block
	mt-2
	text-primary-text
	font-thin
	`}
`;

const CtaBtn = styled(Link)(() => [
	tw`whitespace-no-wrap
        bg-accent-background
        border
        border-transparent
        rounded-md
        py-4
        px-6
        inline-flex
        items-center
        justify-center
        text-2xl
        leading-8
        font-extrabold
        text-accent-text
        focus:outline-none
        focus:border-accent-background
        focus:bg-transparent
        focus:text-accent-background
        transition
        ease-in-out
        duration-150
        mt-10`,
]);

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
}

const Hero: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Wrapper>
			<div className="max-w-2xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				<div className="text-center sm:text-left px-0 sm:px-4 lg:px-0 lg:flex lg:justify-between lg:items-center">
					<div className="max-w-xl">
						<Title>No logs VPN.<br/>For real!</Title>
						<Subtitle>
							Privacy oriented VPN servers installed on your own VM instances.
							No logs or backdoors and 100% transparency
						</Subtitle>
						<CtaBtn to={'/#pricing'}>
							<sup>*</sup> Only ${PRICE} one-time
						</CtaBtn>
						<Asterisk>
							* After which you pay for the VM instance monthly.
						</Asterisk>
					</div>
					<div className="relative w-full h-64 sm:h-72 md:h-96 lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full md:hidden lg:block">
						<World
							mainColor={`${theme`colors.secondary.background`}`}
							pathsColor={`${theme`colors.accent.background`}`}
							continentsColor={`${theme`colors.primary.background`}`}
							className={'w-full h-full'}
						/>
					</div>
				</div>
			</div>

			<Wave fillColor={'var(--bg)'}/>
		</Wrapper>
	);
};

export default Hero;
